import styled from "styled-components"

import Slide from "@material-ui/core/Slide"

import { DeviceConfigurationView } from "src/components/Homes/DeviceDetails/Configuration/DeviceConfigurationView"
import { Z_INDEX_ABOVE_DRAWER } from "src/constants/zindex"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { TextButton } from "src/ui/Button/TextButton"
import { brandText, divider } from "src/ui/colors"
import CloseIcon from "src/ui/icons/close.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function DeviceSettingsPanel({
  device,
  open,
  onClose,
}: {
  device: TDevice
  onClose: () => void
  open: boolean
}) {
  const { t, langKeys } = useTranslate()
  return (
    <Slide direction="left" in={open} mountOnEnter unmountOnExit>
      <Box>
        <div>
          <Top>
            <div>
              <MText variant="bodyS" color="secondary">
                {device.description}
              </MText>
              <MText variant="heading2">{t(langKeys.settings)}</MText>
            </div>

            <TextButton onClick={onClose}>
              <CloseIcon width={24} color={brandText} />
            </TextButton>
          </Top>

          <DeviceConfigurationView devices={[device]} />
        </div>
      </Box>
    </Slide>
  )
}

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 101vh;
  bottom: 0;
  right: 0;
  background: white;
  z-index: ${Z_INDEX_ABOVE_DRAWER};
`

const Top = styled.div`
  padding: ${spacing.M};
  border-bottom: 1px solid ${divider};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`
