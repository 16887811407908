import styled from "styled-components"

import { FeatureBlockerText } from "src/components/FeatureBlockers/FeatureBlockerText"
import { ShortcodeTable } from "src/components/Homes/HomeDetails/Shortcodes/ShortCodeTable"
import { HREF_MINUT_HELP_SHORTCODES } from "src/constants/hrefs"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import {
  DEFAULT_CHECKIN_MESSAGE,
  IGuestCommunication,
} from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { ExpandableSection } from "src/ui/ExpandableSection/ExpandableSection"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ShortcodesSection({
  homeId,
  guestCommunication,
}: {
  guestCommunication: IGuestCommunication | undefined
  homeId: string
}) {
  const { t, langKeys } = useTranslate()

  const guestConnectAvailability = useFeatureAvailability({
    feature: Feature.GUEST_CONNECT,
  })

  return (
    <ExpandableSection
      title={
        <FeatureBlockerText
          hidden={!guestConnectAvailability.ready}
          isFeatureAvailable={guestConnectAvailability.available}
        >
          <TitleBox>
            <div>{t(langKeys.shortcodes)}</div>
          </TitleBox>
        </FeatureBlockerText>
      }
    >
      <Box>
        <div>
          <MText variant="bodyS" color="secondary">
            {t(langKeys.home_short_codes_description)}{" "}
            <ExternalLink href={HREF_MINUT_HELP_SHORTCODES}>
              {t(langKeys.learn_more)}
            </ExternalLink>
          </MText>
        </div>

        <section>
          <ShortcodeTable
            homeId={homeId}
            rowData={[
              {
                id: "checkin_message",
                message:
                  guestCommunication?.checkin_message ||
                  DEFAULT_CHECKIN_MESSAGE,
                name: t(langKeys.check_in_instructions),
              },
            ]}
          />
        </section>
      </Box>
    </ExpandableSection>
  )
}

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const Box = styled.div`
  display: grid;
  grid-gap: ${spacing.M};
  padding-block: ${spacing.M};
`
