import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { Breadcrumbs } from "src/ui/Breadcrumbs/Breadcrumbs"

export function HomeBreadcrumbs({
  homeId,
  name,
}: {
  homeId: string
  name: string
}) {
  const { t, langKeys } = useTranslate()

  return (
    <Breadcrumbs
      items={[
        { label: t(langKeys.home_plural), to: Routes.Homes.location() },
        { label: name, to: Routes.Home.location(homeId) },
      ]}
    />
  )
}
