import styled from "styled-components"

import { getSensorValue } from "src/data/devices/logic/deviceLogic"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { monitoringAvailable } from "src/data/homes/logic/homeUtil"
import { MonitoringType } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHome } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { ErrorIcon } from "src/ui/ErrorIcon/ErrorIcon"
import LocalizedTemperature from "src/ui/LocalizedTemperature"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function DeviceValues({
  device,
  homeId,
}: {
  device: TDevice
  homeId: string
}) {
  const { t, langKeys } = useTranslate()

  const sensorValues = device.latest_sensor_values
  const sensorIsOffline = device.offline ?? false
  const chargeStatus = device.charge_status
  const { orgId } = useOrganization()
  const fetchHome = useFetchHome({ orgId, homeId })

  if (fetchHome.isLoading || fetchHome.isError) {
    return null
  }
  const home = fetchHome.data

  if (sensorIsOffline) {
    return (
      <SensorOfflineBox>
        <ErrorIcon svgProps={{ width: 20 }} />
        <MText variant="bodyS" color="emergency">
          {t(langKeys.home_details_sensor_is_offline)}
        </MText>
      </SensorOfflineBox>
    )
  }

  return (
    <SensorValues>
      {monitoringAvailable({
        type: MonitoringType.SOUND,
        home,
        devices: [device],
      }) && (
        <MBanner type="good" size="small">
          {`${getSensorValue(sensorValues?.sound)} dB`}
        </MBanner>
      )}

      {monitoringAvailable({
        type: MonitoringType.TEMPERATURE,
        home,
        devices: [device],
      }) && (
        <MBanner type="good" size="small">
          {chargeStatus === "connected_charging" ? (
            <MText variant="bodyS" color="secondary">
              {t(langKeys.sensor_data_charging_placeholder)}
            </MText>
          ) : (
            <LocalizedTemperature value={sensorValues?.temperature?.value} />
          )}
        </MBanner>
      )}

      {monitoringAvailable({
        type: MonitoringType.HUMIDITY,
        home,
        devices: [device],
      }) && (
        <MBanner type="good" size="small">
          {chargeStatus === "connected_charging" ? (
            <MText variant="bodyS" color="secondary">
              {t(langKeys.sensor_data_charging_placeholder)}
            </MText>
          ) : (
            `${getSensorValue(sensorValues?.humidity)} % RH`
          )}
        </MBanner>
      )}
    </SensorValues>
  )
}

const SensorValues = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
`

const SensorOfflineBox = styled.div`
  display: flex;
  gap: ${spacing.XS2};
  align-items: center;
`
