import { useState } from "react"
import styled from "styled-components"

import { IconButton } from "@material-ui/core"

import { DeviceDetailsHistory } from "src/components/Homes/HomeDetailsV2/HomeDeviceList/DeviceDetailsV2/DeviceDetailsHistory"
import { DeviceSettingsPanel } from "src/components/Homes/HomeDetailsV2/HomeDeviceList/DeviceDetailsV2/DeviceSettingsPanel"
import { DeviceSummary } from "src/components/Homes/HomeDetailsV2/HomeDeviceList/DeviceDetailsV2/DeviceSummary"
import { usePutDevice } from "src/data/devices/queries/deviceQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { THome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { brandText, divider } from "src/ui/colors"
import { Editable } from "src/ui/Editable/Editable"
import CloseIcon from "src/ui/icons/close.svg"
import P3Sensor from "src/ui/icons/p3-top.svg"
import SettingsCogWheel from "src/ui/icons/settings-cog-wheel.svg"
import { MText } from "src/ui/MText"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"

export function DeviceDetailsV2({
  home,
  device,
  onClose,
}: {
  home: THome
  device: TDevice
  onClose: () => void
}) {
  const { org, orgId } = useOrganization()

  const [showSettings, setShowSettings] = useState(false)

  const hasAdminAccess = getAccessLogic({ role: org.user_role }).hasAdminAccess

  const putDevice = usePutDevice(orgId)

  async function handleEditTitle(title: string) {
    return putDevice.mutate({
      id: device.device_id,
      data: { description: title },
    })
  }

  function handleSettingsClick() {
    setShowSettings(true)
  }

  function handleCloseSettingsClick() {
    setShowSettings(false)
  }

  return (
    <div>
      <Header>
        <Top>
          <div>
            <TitleBox>
              <P3Sensor width={35} height={35} />

              <MText variant="heading2">
                <Editable onSave={handleEditTitle} disabled={!hasAdminAccess}>
                  {device.description}
                </Editable>
              </MText>
            </TitleBox>

            <DeviceSummary
              homeId={home.home_id}
              device={device}
              timezone={home.timezone}
              showTitle={false}
            />
          </div>

          <div>
            <IconButton size="small" onClick={handleSettingsClick}>
              <SettingsCogWheel height={24} width={24} color={brandText} />
            </IconButton>

            <IconButton onClick={onClose} size="small">
              <CloseIcon width={24} color={brandText} />
            </IconButton>
          </div>
        </Top>
      </Header>

      <Show if={!showSettings}>
        <DeviceDetailsHistory device={device} home={home} />
      </Show>

      <DeviceSettingsPanel
        device={device}
        onClose={handleCloseSettingsClick}
        open={showSettings}
      />
    </div>
  )
}

const Header = styled.div`
  z-index: 1;
  position: sticky;
  top: 0;
  background: white;
  padding: ${spacing.M};
  border-bottom: 1px solid ${divider};
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`

const TitleBox = styled.div`
  display: flex;
  gap: ${spacing.S};
  margin-bottom: ${spacing.XS};
`
