import styled from "styled-components"

import { greyScale } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MonitoringCardV2({
  icon,
  title,
  status,
  action,
  children,
}: {
  icon: React.ReactNode
  title: React.ReactNode
  status?: React.ReactNode
  action?: React.ReactNode
  children?: React.ReactNode
}) {
  return (
    <Box>
      <Main>
        <IconBox>{icon}</IconBox>

        <BodyBox>
          <MText variant="subtitle">{title}</MText>

          {status && (
            <StatusBox>
              <MText color="secondary" variant="bodyS">
                {status}
              </MText>
            </StatusBox>
          )}
        </BodyBox>

        {action && <div>{action}</div>}
      </Main>

      {children}
    </Box>
  )
}

const Box = styled.div`
  border: 1px solid ${greyScale[200]};
  border-radius: 8px;
  padding: ${spacing.S};
`
const Main = styled.div`
  display: grid;
  grid-template-columns: auto 3fr auto;
`

const IconBox = styled.div`
  align-self: center;
  margin-right: ${spacing.XS2};
`

const BodyBox = styled.div`
  align-self: center;
  padding: 0 ${spacing.XS};
`

const StatusBox = styled.div`
  display: grid;
  align-content: center;
  margin-top: ${spacing.XS2};
`
