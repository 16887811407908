import styled from "styled-components"

import { GuardAssistCard } from "src/components/Homes/HomeDetailsV2/MonitoringCards/GuardAssistCard"
import { NoiseMonitoringCardV2 } from "src/components/Homes/HomeDetailsV2/MonitoringCards/NoiseMonitoringCardV2"
import { OccupancyCardV2 } from "src/components/Homes/HomeDetailsV2/MonitoringCards/OccupancyCardV2"
import { SecurityAlarmCardV2 } from "src/components/Homes/HomeDetailsV2/MonitoringCards/SecurityAlarmCardV2"
import { SmokingDetectionCardV2 } from "src/components/Homes/HomeDetailsV2/MonitoringCards/SmokingDetectionCardV2"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import { DEFAULT_HOME_NAME, THome } from "src/data/homes/types/homeTypes"
import { spacing } from "src/ui/spacing"

export function MonitoringCards({
  home,
  editable,
  devices,
}: {
  home: THome
  editable: boolean
  devices: TDevice[]
}) {
  const DEFAULT_NOISE_DURATION_SECONDS = 0
  const cigaretteSmokeAvailability = useFeatureAvailability({
    feature: Feature.CIGARETTE_SMOKE,
  })

  const disturbanceMonitoringActive =
    !!home.disturbance_monitoring?.disturbance_monitoring_active

  return (
    <Box>
      <NoiseMonitoringCardV2
        homeId={home.home_id}
        monitoringActive={disturbanceMonitoringActive}
        violations={home.violations}
        toggleAllowed={editable}
        devices={devices}
        noiseDuration={
          home.disturbance_monitoring?.noise_duration_seconds ||
          DEFAULT_NOISE_DURATION_SECONDS
        }
        monitoringState={home.disturbance_monitoring?.state_v2}
        responseService={home.response_service}
      />

      <GuardAssistCard
        orgId={home.organization_id}
        homeId={home.home_id}
        homeName={home.name || DEFAULT_HOME_NAME(home.home_id)}
        responseService={home.response_service}
        monitoringState={home.disturbance_monitoring?.state_v2}
        stateUpdatedAt={home.disturbance_monitoring?.current_state_update_at}
      />

      <OccupancyCardV2 home={home} />

      {cigaretteSmokeAvailability.available &&
        home.smoking_detection?.available && (
          <SmokingDetectionCardV2
            homeId={home.home_id}
            smokingDetection={home.smoking_detection}
            violations={home.violations}
            toggleAllowed={editable}
          />
        )}

      <SecurityAlarmCardV2 home={home} toggleAllowed={editable} />
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`
