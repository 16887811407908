import { useState } from "react"
import styled from "styled-components"

import { Fade } from "@material-ui/core"

import { useFetchGuests } from "src/data/guests/queries/guestQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { TextButton } from "src/ui/Button/TextButton"
import { MDetails } from "src/ui/ExpandableSection/MDetails"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"

export function GuestSection({ homeId }: { homeId: string }) {
  const [open, setOpen] = useState(false)
  const fetchGuests = useFetchGuests(homeId, { limit: 5 })
  const { t, langKeys } = useTranslate()

  return (
    <MDetails
      open={open}
      title={
        <GuestSectionTitleBox>
          <div>{t(langKeys.guest_plural)}</div>
          <Fade in={open}>
            <TextButton
              onClick={(e) => {
                if (open) {
                  e.stopPropagation()
                }
              }}
            >
              {t(langKeys.add)}
            </TextButton>
          </Fade>{" "}
        </GuestSectionTitleBox>
      }
      onChange={() => setOpen((prev) => !prev)}
      id="guest-home-section"
      variant={open ? "accordion" : "default"}
    >
      <Box>
        {fetchGuests.isLoading ? (
          <MSkeleton />
        ) : (
          <InternalLink to={Routes.HomeGuestsV2.location(homeId)}>
            See all guests
          </InternalLink>
        )}
      </Box>
    </MDetails>
  )
}

const GuestSectionTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const Box = styled.div`
  padding-block: ${spacing.M};
`
