import styled from "styled-components"

import { divider } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function CalibrationEmptyState({
  icon,
  body,
  description,
}: {
  icon: React.ReactNode
  body: React.ReactNode
  description: React.ReactNode
}) {
  return (
    <Box>
      <div>{icon}</div>
      <div>
        <MText variant="subtitle">{body}</MText>
        <MText variant="bodyS" color="secondary">
          {description}
        </MText>
      </div>
    </Box>
  )
}

const Box = styled.div`
  margin-top: ${spacing.M};
  padding-top: ${spacing.M};
  border-top: 1px solid ${divider};
  text-align: center;
  justify-content: center;
  display: grid;
  gap: ${spacing.XS};
`
